.curtain {
  margin: 0 auto;
  width: 100%;
  overflow: hidden;

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .curtain__panel--left {
      transform: translateX(-100);
    }

    // Slide the second panel
    .curtain__panel--right {
      transform: translateX(100);
    }
  }

  &__panel {
    display: flex;
    align-items: center;
    background: white;
    color: #fff;
    float: left;
    position: relative;
    width: 50%;
    height: 100vh;
    max-height: 1080px;
    transition: all 1.5s ease-out;
    z-index: 2;

    &--left {
      justify-content: flex-end;
      transform: translateX(-100%);
    }

    &--right {
      justify-content: flex-start;
      transform: translateX(100%);
    }
  }

  &__content {
    align-items: center;
    background: #333;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 1;
    width: 100%;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
