.header_content {
  max-width: 220px;
  width: 100%;
  margin: 0 auto;
  position: sticky;
  top: 0;
  .header_logo {
    max-width: 120px;
    height: 90px;
    width: 100%;
    margin: 0 auto;
    .logo {
      width: 100%;
    }
  }
}

@keyframes logo-flip {
  0% {
    transform: scale(1) rotate3d(0, 0, 0, 0deg);
  }
  25% {

  }
  50% {
    transform: scale(1) rotate3d(0, 1, 0, 360deg);
  }
  100% {
    transform: scale(1) rotate3d(0, 0, 0, 0deg);
  }
}

.nbar {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: white;
  height: 100px;
  border: 1px solid #E3E3E3;
  text-align: center;
  transition: top 0.6s;
  color: #0a0c0d !important;

  &-show {
    background-color: white;
    transition: all 0.3s ease-in-out;
  }
}

.navibar-menu-item-title {
  color: #000 !important;
}

.nav-link {
  color: black !important;
}
