.navibar {
  height: 100%;
  padding: 0;
  z-index: 999;
  transition: all 0.3s ease-in-out;


  &-menu {
    padding-top: 4rem;
    padding-left: 30%;
    display: flex;
    flex-direction: column;

    &-item {
      user-select: none;
      font-size: 0.5rem !important;
      padding-bottom: 1.5rem;

      &:hover {
        cursor: default;
        color: lightgray;
        text-decoration: none;
      }

      &-title {
        text-decoration: none;
        width: 100%;

        &:hover {
          text-decoration: none;
          cursor: pointer;
          border-bottom: 1px solid $color-atta-green;
          margin-bottom: -1px;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.nav-link {
  color: whitesmoke !important;
}

.navibarMain {
  height: 100%;
  padding: 0;
  z-index: 105;
  transition: all 0.3s ease-in-out;


  &-menu {
    padding-top: 4rem;
    padding-left: 30%;
    display: flex;
    flex-direction: column;

    &-item {
      user-select: none;
      font-size: 0.5rem !important;
      padding-bottom: 1.5rem;

      &:hover {
        cursor: default;
        color: lightgray;
        text-decoration: none;
      }

      &-title {
        text-decoration: none;
        width: 100%;

        &:hover {
          text-decoration: none;
          cursor: pointer;
          border-bottom: 1px solid $color-atta-green;
          margin-bottom: -1px;
        }
      }
    }
  }
}