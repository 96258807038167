.containerM {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.news,
.projects,
.contact {
  display: flex;
  width: 100%;
  margin: 0 auto;
  .about_inner,
  .news_inner,
  .projects_inner,
  .contact_inner {
    width: 100%;
    margin: 0 auto;
    .header_title {
      margin-top: 122px;
      margin-bottom: 22px;
      font-size: 42px;
      font-weight: 300;
      color: #000000;
    }
    .content {
      width: 100%;
      padding: 0;
      margin: 0 auto;
      .news-img,
      .about_us-img,
      .achievements {
        width: 100%;
      }
    }
  }
}

.news-img {
  width: 50%;
}
