.stats {
  &-inner {
    display: flex;
    justify-content: space-around;

    &-item {
      max-width: 250px;
      width: 100%;
      margin: 0 auto;

      &-img-wrap {
        position: relative;
        max-width: 250px;
        width: 100%;
        height: 180px;

        &::after {
          font-size: 80px;
          position: absolute;
          top: 10px;
          left: 95px;
          z-index: 10;
        }

        .stat-img {
          max-width: 100%;
        }
      }

      .stat-img-wrap1::after {
        content: '240';
      }
      .stat-img-wrap2::after {
        content: '200';
      }
      .stat-img-wrap3::after {
        content: '20';
        top: 10px;
        left: 110px;
        z-index: 10;
      }
      .stat-img-wrap4::after {
        content: '22';
        top: 10px;
        left: 140px;
        z-index: 10;
      }

      h2 {
        font-weight: 300;
      }
    }
  }
}
