.intro {
  height: 100vh;
  background-size: cover;

  .intro-container {
    width: 100%;
    height: 100vh;
    margin: 0 auto;

    .intro-inner {
      // width: 100%;
      // margin: 0 auto;

      .slider {
        width: 100%;
        // height: 100%;
        // top: -20px;
        // left: 0;
        margin: 0 auto;

        .slider-item {
          height: 100vh;
          width: 100%;
          margin: 0 auto;
          font-size: 18px;
          z-index: 1;

          .item-img {
            background-color: rgba(0,0,0, 1);
            max-width: 100%;
            max-height: 1080px;
          }
        }
      }
    }
  }
}
// .item_img{

// }
// .carousel {
//   position: relative;
//   width: 100%;
//   margin: 0 auto;
// }
// .carousel-control-prev {
//   display: none;
// }

// .carousel-indicators {
//   justify-content: left;

//   li {
//     max-width: 45px;
//     width: 100%;
//   }
// }

// .carousel-item {
//   max-width: 1920px;
//   width: 100%;
//   margin: 0 auto;
//   h3 {
//     color: black;
//     font-family: "Montserrat", sans-serif;
//     font-size: 45px;
//     text-align: left;
//   }
//   .img_outer {
//     width: 1920px;
//     height: 100vh;

// .slider_backgr_img {
//   max-width: 1920px;
//   width: 100%;
//   height: 100vh;

// }
.first_slide {
  background: url('../../img/slider/OgniSibiri.jpg') no-repeat center center/cover;
}
// .second_slide {
//   background: url("../../img/slider/SnejnayaMilya.jpg") no-repeat;
// }
// .third_slide {
//   background: url("../../img/slider/OgniSibiri.jpg") no-repeat;
// }
// .fourth_slide {
//   background: url("../../img/slider/SnejnayaMilya.jpg") no-repeat;
// }
//   }
// }
