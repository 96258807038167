@import "assets/assets";
@import "~animate.css";
@import 'pages/about/about';
@import 'pages/career/career';
@import 'pages/contact/contact';
@import 'pages/projects/projects';
@import 'pages/news/news';
@import 'root';
@import 'components/header/header';
@import 'components/sidebar/sidebar';
@import 'components/stats/stats';
@import 'components/navbar/navbar';
@import 'components/quill/quill';
@import 'components/awards/awards';
@import 'components/footer/footer';
@import 'components/slider/slider';
@import 'components/curtain/curtain';
@import '../modules/Projects/styles/projects.mobile';
@import '../modules/News/styles/mobile.news';
@import '../modules/About/styles/about.mobile';
