.awards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 30px;
  &-inner {
    display: flex;
    flex-direction: column;
  }
}
.award-inner-item-img-wrap {
  // opacity: 0.3;
  max-width: 262px;
  width: 100%;
  .award-img {
    opacity: 0.3;
    width: 100%;
  }
}
.active{
  opacity: 1;
  width: 100%;
}
.award-inner-item-img-wrap:hover{
  opacity: 1;
  transition: opacity 0.5s linear;
}

.awards-description {
  .text {
    font-size: 20px;
    color: #454545;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .sign {
    font-size: 16px;
    color: #9b9b9b;
    letter-spacing: 0;
    line-height: 30px;
    padding-bottom: 20px;
  }
}

.indicators-container {
  background-color: white;
}

.carousel-atta {
  padding: 50px 120px;
  background-color: #F6F6F6;
  &-inner {
    width: 100%;
  }
  &-indicators {
    background-color: white;
    margin: 20px 0 0;
    padding-top: 20px;

    li {
      height: 2px;
      background-color: gray;
    }

    &-news {
      background-color: white;
      margin: 20px 0 0;

      li {
        height: 2px;
        background-color: gray;
      }
    }
  }

  &-controls {
    background-color: white;
  }

  &-control {
    width: 10%;
    height: 60%;

    &:hover {
      cursor: pointer;
    }
  }
}

.carousel-indicators .active {
  background-color: $color-atta-green;
}

.award {
  &:hover {
    cursor: pointer;
  }
}

.carousel-control-next-icon {
  height: 32px;
  width: 32px;
  background-image: url("../../../img/slider/chevron-right.svg");
}

.carousel-control-prev-icon {
  height: 32px;
  width: 32px;
  background-image: url("../../../img/slider/chevron-left.svg");
}
