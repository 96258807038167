@media (min-width: 280px) {
  .news {
    &-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 5%;
    }

    .article {
      width: 100%;
    }
  }
}
/* smartphones, iPhone, portrait 480x320 phones */
@media (min-width:320px)  {
  .news {
    &-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 5%;
    }

    .article {
      width: 100%;
    }
  }

  .article {
    &-details {
      &-info {
        width: 100%;
        margin: 0 auto;
      }
      &-container {
        &-name {
          position: absolute;
          width: 100%;
          color: whitesmoke;
          text-align: center;
          bottom: 1rem;
          font-size: 0.8rem;
          font-weight: bold;
        }
      }

      &-other {
        &-title {
          margin: 1rem auto;

          span {
            font-weight: bold;
            font-size: 2rem;
          }
        }
      }

      &-slider {
        &-item {
          display: flex;
          width: 100%;
          flex-direction: column;

          .article {
            width: 100%;
          }
        }
      }
    }
  }
}
/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media (min-width:481px)  {
  .news {
    &-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 5%;
    }
  }
}
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) {
  .news {
    &-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 5%;
    }

    .article {
      width: calc(50% - 2px);

    }
  }

  .article {
    &-details {
      &-info {
        width: 70%;
        margin: 0 auto;
      }

      &-container {
        &-name {
          position: absolute;
          width: 100%;
          color: whitesmoke;
          text-align: center;
          bottom: 1rem;
          font-size: 1.5rem;
          font-weight: bold;
        }
      }

      &-slider {
        &-item {
          display: flex;
          flex-direction: row;

          .article {
            width: calc(50% - 2px);
          }
        }
      }
    }
  }
}
@media (min-width:1281px) { /* hi-res laptops and desktops */ }

