.contacts {
  display: flex;
  flex-direction: row;
  &-container {
    width: 100%;
    background-color: whitesmoke;

    &-title {
      margin-bottom: 2rem;
    }

    &-data {
      display: flex;
      flex-direction: column;
      margin: 8rem 5rem;
    }

    &-location {
      img {
        object-fit: cover;
        height: 100%;
        width: 715px;
      }
    }
  }
}
