@media (min-width: 280px) {
  .projects {
    &-list {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-bottom: 5%;
      height: 100%;
    }
  }

  .project {
    width: 100%;
  }
}
/* smartphones, iPhone, portrait 480x320 phones */
@media (min-width:320px)  {
  .projects {
    &-list {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-bottom: 5%;
      height: 100%;
    }
  }

  .project {
    width: 100%;

    &-details {
      &-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 2rem auto;

        &-item {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          margin-bottom: 0.5rem;
          margin-top: 0.5rem;
        }
      }

      &-awards {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 2rem auto;

        span, b {
          font-size: 1rem;
        }

        &-year {
          font-size: 2rem;
          color: $color-atta-green;
          font-weight: bold;
        }
      }
    }
  }

  .pr-details-slider-item {
    &-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      justify-content: space-around;

      img {
        object-fit: cover;
        max-height: 337px;
        width: 33%;
      }

      img:hover {
        cursor: pointer;
      }
    }
  }

  .prItem {
    z-index: 0;
    width: 100%;

    &-item {
      height: 350px;
      width: 100%;
      position: relative;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      margin-bottom: 2px;

      &:hover {
        cursor: pointer;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.1);
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
      }

      &-title {
        position: absolute;
        color: white;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 30%, rgba(0,0,0,0) 80%);
        //TODO: transition
        &:hover {
          text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);

          h6 {
            color: $color-atta-green;
          }
        }
      }
    }
  }

  .indicators-container {
    background-color: white;
    padding-top: 2rem;
  }
}
/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media (min-width:481px)  {
  .projects {
    &-list {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-bottom: 5%;
      height: 100%;
    }
  }

  .project {
    width: 100%;
  }
}
@media (min-width:641px)  {
  .project {
    width: 100%;

    &-details {
      &-awards {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 6rem auto;

        span, b {
          font-size: 1.5rem;
        }

        &-year {
          color: $color-atta-green;
          font-weight: bold;
        }
      }
    }
  }
}
@media (min-width:961px)  {}
@media (min-width:1025px) {
  .projects {
    &-list {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-bottom: 5%;
      height: 100%;
    }
  }

  .project {
    width: calc(50% - 2px);

    &-details {
      &-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 3rem auto;

        &-item {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 15%;

          &-title {
            width: 100%;
            font-size: 0.8rem;
            font-weight: bold;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }

          &-status {
            font-weight: bold;
            font-size: 0.7rem;
          }
        }
      }

      &-awards {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 3rem auto;

        span, b {
          font-size: 2rem;
        }

        &-year {
          color: $color-atta-green;
          font-weight: bold;
        }
      }
    }
  }

  .prItem {
    z-index: 0;
    width: calc(33%);

    &-item {
      height: 350px;
      width: 100%;
      position: relative;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      margin-bottom: 2px;

      &:hover {
        cursor: pointer;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.1);
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
      }

      &-title {
        position: absolute;
        color: white;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 30%, rgba(0,0,0,0) 80%);
        //TODO: transition
        &:hover {
          text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);

          h6 {
            color: $color-atta-green;
          }
        }
      }
    }
  }

  .pr-details-slider-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
  }

  .indicators-container {
    background-color: white;
    padding-top: 0.5rem;
  }
}
@media (min-width:1281px) { /* hi-res laptops and desktops */ }

