.about {
  display: flex;
  width: 100%;
  margin: 0 auto;

  &-inner {
    width: 100%;
    margin: 0 auto;

    &-header {
      margin: 22px 0;
      font-size: 42px;
      font-weight: 300;
      color: $color-black;
      letter-spacing: 0;
      line-height: 56px;
    }

    &-content {
      width: 100%;
      padding: 0;
      margin-top: 50px;

      .about_us-img,
      .achievements {
        width: 100%;
      }
    }
  }
}

.text {
  margin: 0;
  max-width: 1200px;
  width: 100%;
  text-align: left;
  .about_content_header {
    margin-bottom: 45px;
    font-size: 42px;
    font-weight: 300;
    color: #000000;
    letter-spacing: 0;
    line-height: 56px;
  }
  .about_content_text {
    color: #454545;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 30px;
  }
}

.about_video {
  padding-bottom: 150px;

  .video {
    margin: 0 auto;
    width: 100%;
    .wrapper {
      width: 100%;
      margin: 0 auto;
    }
  }
}
