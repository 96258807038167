.slider {

  &-caption {
    width: 100%;
    margin-bottom: 40px;
    text-align: left;
  }

  &-control {
    z-index: 100;
    &:hover {
      cursor: pointer;
    }
  }

  &-indicators {
    li {
      height: 1px;
      width: 2rem;
      background-color: gray;
    }
  }

  &-indicators .carousel-indicators .active {
    background-color: white;
  }
}
