.projects {

  &-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 5%;
    height: 100%;
  }
}

.project {
  width: calc(50% - 1px);

  &-item {
    height: 350px;
    width: 100%;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-bottom: 2px;
    transition: transform 3s ease;

    &:hover {
      cursor: pointer;
      z-index: 999;
      transform: scale(1.1);
      background-color: rgba(0, 0, 0, 0.1);
      text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }

    &-title {
      position: absolute;
      color: white;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 30%, rgba(0,0,0,0) 80%);
      transition: transform 3s ease;
//TODO: transition
      &:hover {
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
        transform: scale(0.94);
      }

      span {
        position: absolute;
        font-size: 1rem;
        font-weight: normal;
        bottom: 10px;
        padding-left: 20px;
      }
    }
  }
}

.project {
  &-info {
    width: 80%;
    margin: 0 auto;
  }

  &-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    margin: 100px auto 0 auto;

    &-other {

      margin-bottom: 2rem;

      &-title {
        width: 80%;
        margin: 2rem auto;

        span {
          font-weight: bold;
          font-size: 48px;
        }
      }
    }

    &-header {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        object-fit: cover;
        width: 100%;
      }
    }

    &-container {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 30%, rgba(0,0,0,0) 80%);
      &-name {
        position: absolute;
        width: 100%;
        color: white;
        text-align: center;
        bottom: 1rem;
        font-size: 2rem;
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
      }
    }

    .details-animation {
      animation: fadeInUp;
      animation-duration: 2s;
    }

    &-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 3rem auto;

      &-item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 15%;

        &-title {
          width: 100%;
          font-size: 16px;
          font-weight: bold;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        &-status {
          font-weight: bold;
          font-size: 20px;
        }
      }
    }

    &-image {
      width: 100%;

      img {
        object-fit: contain;
        width: 100%;
      }

      &-text {
        margin: 1rem auto;
        width: 100%;
      }
    }

    &-awards {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin: 6rem auto;

      span, b {
        font-size: 48px;
      }

      &-year {
        color: $color-atta-green;
        font-weight: bold;
      }
    }

    &-slider {
      width: 80%;
      margin: 2rem auto;

      &-other {

        &-item {
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          img {
            object-fit: cover;
            max-height: 337px;
            width: 33%;
          }

          img:hover {
            cursor: pointer;
          }
        }
      }

      &-inner {
        max-width: 1920px;
        height: 100%;
      }

      &-item {
        width: 100%;
        object-fit: contain;

        img {
          max-width: 100%;
          width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}

.atta-logo {
  height: 6rem;
  position: fixed;
  z-index: 999;
  cursor: pointer;
}
