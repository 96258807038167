.sidebar_left,
.sidebar_right {
  text-align: center;
  max-width: 220px;
  width: 100%;
  margin: 0 auto;
}
.sidebar_left {
  position: relative;
}
