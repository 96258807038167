@media (min-width: 280px) {

}
/* smartphones, iPhone, portrait 480x320 phones */
@media (min-width:320px) {

  .award {
    width: calc(33% - 1px);
    height: 100px;

    &-item {
      height: 100%;
      position: relative;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      margin-bottom: 2px;

      &:hover {
        cursor: pointer;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.1);
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
      }

      &-title {
        position: absolute;
        color: white;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.1) 30%, rgba(0,0,0,0) 80%);
        //TODO: transition
        &:hover {
          text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
        }

        span {
          position: absolute;
          font-size: 8px !important;
          font-weight: normal;
          bottom: 10px;
          padding-left: 20px;
        }
      }
    }
  }

  .text {
    margin: 0;
    max-width: 1200px;
    width: 100%;
    text-align: left;
    .about_content_header {
      margin-bottom: 45px;
      font-size: 42px;
      font-weight: 300;
      color: #000000;
      letter-spacing: 0;
      line-height: 56px;
    }
    .about_content_text {
      color: #454545;
      font-size: 0.7rem;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
  .stats {
    &-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-item {
        margin: 1rem auto;

        h2 {
          font-size: 1.5rem;
        }

        &-img-wrap {
          height: 130px;
        }
      }
    }
  }

  .carousel-atta {
    padding: 50px 0;
  }

  .carousel-control-next {
    height: 90%;
  }
  .carousel-control-prev {
    height: 90%;
  }

  .awards {
    display: flex;
    flex-direction: row;
    padding-left: 2rem;
    padding-right: 2rem;

    img {
      width: calc(33% - 2px);
    }

    &-description {
      padding-left: 2rem;
      padding-right: 2rem;

      .text {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  .about_video {
    padding-bottom: 2rem;
    margin-top: 5rem;
  }
}
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  @media (min-width: 481px) {
    .carousel-control-next {
      height: 90%;
    }
    .carousel-control-prev {
      height: 90%;
    }

  }
  @media (min-width: 641px) {

    .award {
      width: calc(33% - 1px);
      height: 200px;

      &-item {
        height: 100%;
        position: relative;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        margin-bottom: 2px;

        &:hover {
          cursor: pointer;
          z-index: 999;
          background-color: rgba(0, 0, 0, 0.1);
          text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
        }

        &-title {
          position: absolute;
          color: white;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.1) 30%, rgba(0,0,0,0) 80%);
          //TODO: transition
          &:hover {
            text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
          }

          span {
            position: absolute;
            font-size: 8px !important;
            font-weight: normal;
            bottom: 10px;
            padding-left: 20px;
          }
        }
      }
    }

    .carousel-control-next {
      height: 90%;
    }
    .carousel-control-prev {
      height: 90%;
    }

    .awards {
      display: flex;
      flex-direction: row;
      padding-left: 5rem;
      padding-right: 5rem;

      &-description {
        padding-left: 5rem;
        padding-right: 5rem;

        .text {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
  @media (min-width: 961px) {

    .award {
      width: calc(33% - 1px);
      height: 300px;

      &-item {
        height: 100%;
        position: relative;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        margin-bottom: 2px;

        &:hover {
          cursor: pointer;
          z-index: 999;
          background-color: rgba(0, 0, 0, 0.1);
          text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
        }

        &-title {
          position: absolute;
          color: white;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.1) 30%, rgba(0,0,0,0) 80%);
          //TODO: transition
          &:hover {
            text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
          }

          span {
            position: absolute;
            font-size: 8px !important;
            font-weight: normal;
            bottom: 10px;
            padding-left: 20px;
          }
        }
      }
    }


    .carousel-control-next {
      height: 90%;
    }
    .carousel-control-prev {
      height: 90%;
    }
    .awards {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-left: 5rem;
      padding-right: 5rem;

      img {
        width: calc(25%);
      }

      &-description {
        padding-left: 7rem;
        padding-right: 6rem;
      }
    }
    .stats {
      &-inner {
        display: flex;
        flex-direction: row;

      }
    }
  }
  @media (min-width: 1025px) {

    .indicators-container-about {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .carousel-control-next {
      height: 90%;
      padding-left: 2rem;
    }
    .carousel-control-prev {
      height: 90%;
      padding-right: 2rem;
    }

    .award {
      width: calc(33% - 1px);

      &-item {
        height: 300px;
        position: relative;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        margin-bottom: 2px;

        &:hover {
          cursor: pointer;
          z-index: 999;
          background-color: rgba(0, 0, 0, 0.1);
          text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
        }

        &-title {
          position: absolute;
          color: white;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.1) 30%, rgba(0,0,0,0) 80%);
          //TODO: transition
          &:hover {
            text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
          }

          span {
            position: absolute;
            font-size: 0.8rem !important;
            font-weight: normal;
            bottom: 10px;
            padding-left: 20px;
          }
        }
      }
    }

    .awards {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-left: 4rem;
      padding-right: 4rem;
    }
    .text {
      margin: 0;
      max-width: 1200px;
      width: 100%;
      text-align: left;
      .about_content_header {
        margin-bottom: 45px;
        font-size: 42px;
        font-weight: 300;
        color: #000000;
        letter-spacing: 0;
        line-height: 56px;
      }
      .about_content_text {
        color: #454545;
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 30px;
      }
    }
    .stats {
      &-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        &-item {
          max-width: 250px;
          width: 100%;
          margin: 0 auto;

          &-img-wrap {
            position: relative;
            max-width: 250px;
            width: 100%;
            height: 180px;

            &::after {
              font-size: 80px;
              position: absolute;
              top: 10px;
              left: 95px;
              z-index: 10;
            }

            .stat-img {
              max-width: 100%;
            }
          }

          .stat-img-wrap1::after {
            content: '240';
          }

          .stat-img-wrap2::after {
            content: '200';
          }

          .stat-img-wrap3::after {
            content: '20';
            top: 10px;
            left: 110px;
            z-index: 10;
          }

          .stat-img-wrap4::after {
            content: "22";
            top: 10px;
            left: 140px;
            z-index: 10;
          }

          h2 {
            font-weight: 300;
          }
        }
      }
    }
  }
@media (min-width:1281px) {
  .about_video {
    padding-bottom: 2rem;
    margin-top: 5rem;
  }
}

