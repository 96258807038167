.footer {
  border-top: 1px solid lightgrey;
  width: 100%;
  margin: 0 auto ;
  padding-top: 50px;
  display: flex;
  .footer_inner {
    width: 100%;
    margin: 0 auto;
    .footer_projects,
    .footer_nav {
      margin-bottom: 50px;
      .footer-header {
        font-size: 20px;
        font-weight: 300;
        color: #000;
        letter-spacing: 0;
        line-height: 30px;
        margin-bottom: 10px;
      }
      .footer_links {
        width: 100%;
        margin:0 auto;
        .footer_link-item {
          margin-right: 45px;
          font-size: 16px;
          color: #9B9B9B;
          letter-spacing: 0;
          line-height: 30px;
        }
      }
    }
  }
}
