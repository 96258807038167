.career{
  display: flex;
  width: 100%;
  margin: 0 auto;

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    border-radius: 5px;
    height: 50px;
    width: 200px;
    color: whitesmoke;
    background-color: $color-atta-green;
    user-select: none;
  }

  &-inner {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;

    &-header {
      margin: 22px 0;
      font-size: 42px;
      font-weight: 300;
      color: $color-black;
      letter-spacing: 0;
      line-height: 56px;
    }
    &-content {
      max-width: 1480px;
      width: 100%;
      padding: 0;
      margin-top: 50px;
      margin-bottom: 100px;

      .career-img{
        width: 100%;
        max-height: 600px;
      }
    }
  }
}
.text {
  margin: 0;
  max-width: 1480px;
  width: 100%;
  text-align: left;
  .career_content_header {
    margin-bottom: 45px;
    font-size: 42px;
    font-weight: 300;
    color: #000000;
    letter-spacing: 0;
    line-height: 56px;
  }
}
.vacancies{
  background-color: #F6F6F6;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;

  &-inner {
    width: 95%;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
    }
  }
}

.vac-header {
  background-color: white;
  border: none;
  padding: 12px 15px;
}

.icon-atta {
  margin-top: 5px;
  height: 24px;
  width: 24px;
}

.icon-atta-active {
  height: 24px;
  width: 24px;
  margin-top: 5px;
}

.vac-title {
  font-size: 24px;
}

.vacancy_title {
  margin-bottom: 50px;
}
.vacancy_description {
  background-color: #FFF;
}

.atta-green {
  fill: $color-atta-green
}

.cursor {
  &:hover {
    cursor: pointer;
  }
}