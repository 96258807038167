.news {
  &-footer {
    display: flex;
    width: 100%;
    margin: 0 auto;
  }
  &-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5%;
  }

  &-more {
    width: 100%;
    &-btn {
      -webkit-appearance: none;
      border: none;
      background-color: $color-atta-green;
      text-align: center;
      color: whitesmoke;
      height: 50px;
      font-size: 16px;
      width: 100%;
    }
  }
}

.first-article {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &-title {
    width: calc(50% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      cursor: pointer;
      color: $color-atta-green;
    }
  }
}

.article {
  z-index: 0;
  width: calc(50% - 1px);

  &-more {
    background-color: #F6F6F6;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 50px 0;

    &-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }

    &-title {
      display: flex;
      flex-direction: column;
      margin: 1rem auto;
      height: 100px;
      width: 90%;

      &:hover {
        cursor: pointer;
        color: $color-atta-green;
      }
    }
  }

  &-details {
    width: 95%;
    margin: 100px auto 1rem auto;
    &-header {
      position: relative;
      max-height: 550px;
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        object-fit: cover;
        width: 100%;
      }
    }

    &-container {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 30%, rgba(0,0,0,0) 80%);

      &-name {
        position: absolute;
        width: 100%;
        color: whitesmoke;
        text-align: center;
        bottom: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
      }
    }

    &-info {
      width: 70%;
      margin: 0 auto;
    }

    &-other {
      margin-bottom: 2rem;

      &-title {
        margin: 2rem auto;

        span {
          font-weight: bold;
          font-size: 48px;
        }
      }
    }

    &-slider {
      margin: 4rem auto;

        &-item {
          display: flex;
          flex-direction: row;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
          justify-content: space-around;

          img {
            object-fit: cover;
            max-height: 337px;
            width: 33%;
          }

          img:hover {
            cursor: pointer;
          }
        }

      &-inner {
        height: 100%;
      }

      &-item {
        width: 100%;
        object-fit: contain;

        img {
          max-width: 100%;
          width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  &-item {
    height: 350px;
    width: 100%;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-bottom: 2px;

    &:hover {
      cursor: pointer;
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.1);
      text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }

    &-title {
      position: absolute;
      color: white;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 30%, rgba(0,0,0,0) 80%);
      //TODO: transition
      &:hover {
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);

        h6 {
          color: $color-atta-green;
        }
      }

      span {
        position: absolute;
        font-size: 1rem;
        font-weight: normal;
        bottom: 10px;
        padding-left: 20px;
      }
    }
  }
}
